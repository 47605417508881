.sd-completedpage {
  font-family: $font-family;
  font-weight: bold;
  box-sizing: border-box;
  text-align: center;
  height: auto;
}

.sd-completedpage,
.sd-completed-before-page {
  // h3 {
  //   font-size: calcFontSize(1.5);
  // }

  h1 {
    @include articleXXLargeFont;
  }

  h2 {
    @include articleXLargeFont;
  }

  h3 {
    @include articleLargeFont;
  }

  h4,  
  h5,
  h6 {
    @include articleMediumFont;
  }

  td,
  span,
  div,
  p {
    @include articleDefaultFont;
  }

  a {
    color: $primary;
  }

  button {
    display: flex;
    align-items: center;
    padding: calcSize(1.5) calcSize(4);
    vertical-align: baseline;
    text-align: center;
    background-color: $background;
    box-shadow: $shadow-small;
    border: none;
    border-radius: calcSize(0.5);
    cursor: pointer;
    user-select: none;
    outline: solid calcSize(0.25) transparent;

    &:hover {
      background-color: $background-dark;
    }
    &:focus {
      box-shadow: 0 0 0 2px $primary;
    }

    span {
      display: flex;
      align-items: center;
      flex-grow: 1;
      justify-content: center;
      color: $primary;
      font-weight: 600;
      font-style: normal;
      font-family: $font-family;
      font-size: $font-questiontitle-size;
      line-height: multiply(1.5, $font-questiontitle-size);
    }
  }
}

.sd-completedpage:before,
.sd-completedpage:after {
  display: block;
  width: calcSize(9);
  margin-left: calc(50% - 4.5 * #{$base-unit});
  content: "";
}

.sd-completedpage:before {
  height: calcSize(13);
}

.sd-completedpage:after {
  height: calcSize(2);
}